import { ActionReducerMap } from '@ngrx/store';
import { AppStateInterface } from '@rdlabo/odss-mobile-api/src/endpoint/functions/transaction/transaction.api';

import * as transaction from './transaction';
import * as task from './task';

export const reducers: ActionReducerMap<AppStateInterface> = {
  transaction: transaction.reducer,
  task: task.reducer,
};
