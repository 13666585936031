{
  "name": "odss-mobile",
  "version": "3.2.13",
  "author": "Masahiko Sakakibara",
  "engines": {
    "node": "^20.0.0"
  },
  "scripts": {
    "api": "cd api && npm run start",
    "fmt": "npm run fmt:app && npm run fmt:api",
    "fmt:app": "prettier --parser typescript --write \"app/projects/**/*.ts\" &&  prettier --parser angular --write \"./app/projects/**/*.html\"",
    "fmt:api": "prettier --write \"./api/src/**/*.ts\" \"api/src/tests/**/*.ts\" \"./api/output/**/*.ts\"",
    "lint:fix": "cd app && npm run lint && cd ../api && npm run lint",
    "build:ionic": "cd api && npm run build && cd ../app && npm run production",
    "build:ci": "npm run build:ionic",
    "release": "np --no-publish --yolo",
    "prepare": "husky install"
  },
  "repository": {
    "type": "git",
    "url": "git@github.com:odss-team/odss-mobile.git"
  },
  "bugs": {
    "url": "git@github.com:odss-team/odss-mobile.git/issues"
  },
  "dependencies": {
    "lerna": "^3.20.2",
    "np": "^6.3.2"
  },
  "devDependencies": {
    "husky": "^8.0.1",
    "lint-staged": "^10.5.4",
    "pre-commit": "^1.2.2",
    "prettier": "^4.0.0-alpha.8"
  }
}
