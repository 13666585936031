import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
  AlertController,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRouterLink,
  IonText,
  IonTitle,
  IonToolbar,
  NavController,
  Platform,
  ViewDidEnter,
} from '@ionic/angular/standalone';
import { HelperService } from '../../../../shared/src/lib/services/helper.service';
import { AuthService } from '../../../../shared/src/auth/auth.service';
import { StorageService } from '../../../../shared/src/lib/services/storage.service';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '@rdlabo/odss-mobile-api/src/endpoint/functions/transaction/transaction.api';
import { actionTransaction, transactionDefault } from '../../reducer/transaction';
import { IDatabase, SlipType } from '../../config/constant';
import packageInfo from '../../../../../../package.json';
import { Geolocation } from '@capacitor/geolocation';
import { App } from '@capacitor/app';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { KeyValuePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
  standalone: true,
  imports: [RouterLink, KeyValuePipe, IonRouterLink, IonHeader, IonToolbar, IonTitle, IonText, IonContent, IonList, IonListHeader, IonLabel, IonItem, IonIcon],
})
export class HomePage implements OnInit, OnDestroy, ViewDidEnter {
  public SlipType = SlipType;
  public version = packageInfo.version;
  public titleEnum = {
    odss: '小田商店',
    futaba: 'フタバ水栓',
  };
  public title = this.titleEnum.odss;
  public emailUser = '';
  public initSubscription$: Subscription[] = [];

  private readonly helper = inject(HelperService);
  public readonly auth = inject(AuthService);
  private readonly alertCtrl = inject(AlertController);
  private readonly storage = inject(StorageService);
  private readonly navCtrl = inject(NavController);
  private readonly store: Store<AppStateInterface> = inject(Store);
  private readonly alertController = inject(AlertController);
  public readonly platform = inject(Platform);
  private readonly router = inject(Router);
  private readonly http = inject(HttpClient);

  constructor() {}

  async ngOnInit() {
    if (this.platform.is('hybrid')) {
      const { version } = await App.getInfo();
      this.version = version;
    }

    this.initSubscription$.push(
      this.auth.getState().subscribe((data) => {
        this.emailUser = data?.email?.split('@')[0];
      }),
    );

    this.initSubscription$.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(async (params: any) => {
        firstValueFrom(
          this.http.post<void>(environment.api() + 'analytics', {
            route: params.url,
          }),
        ).catch();

        FirebaseAnalytics.logEvent({
          name: params.url,
          params: {
            url: params.url,
          },
        }).catch();
      }),
    );
  }

  async ngOnDestroy() {
    this.initSubscription$.forEach((subscription) => subscription.unsubscribe());
  }

  ionViewDidEnter() {
    if (!localStorage.hasOwnProperty('apiKey') || !localStorage.getItem('apiKey')) {
      this.doSignout(true).catch();
    } else {
      this.title = this.titleEnum[localStorage.getItem('apiKey') as IDatabase];
    }

    Geolocation?.getCurrentPosition()
      .then((data) => (this.helper.position = data))
      .catch(() => undefined);
  }

  async checkPosition() {
    const position = this.helper.position || (await Geolocation?.getCurrentPosition());
    await this.helper.presentToast({
      message: `[${position.coords.latitude}/${position.coords.longitude}]`,
    });
  }

  public async doTransaction(type: string): Promise<boolean> {
    const userName = await this.helper.getUserName();

    this.store.dispatch(
      actionTransaction(
        Object.assign(transactionDefault(), {
          slipType: parseInt(type, 10),
          userName,
        }),
      ),
    );
    if ([3, 5, 12].includes(parseInt(type, 10))) {
      return this.navCtrl.navigateForward('/transaction/pickup');
    } else {
      return this.navCtrl.navigateForward('/transaction/choice/company');
    }
  }
  public doRestore() {
    this.navCtrl.navigateForward('/transaction/restore');
  }

  public async doSignout(force = false): Promise<void> {
    if (force === true) {
      localStorage.clear();
      await Promise.all([this.storage.clear(), this.auth.signOut()]);
      this.navCtrl.navigateForward('/auth/signin');
      return;
    }
    const alert = await this.alertController.create({
      header: '確認',
      message: 'サインアウトしてもよろしいですか？',
      buttons: [
        {
          text: 'いいえ',
        },
        {
          text: 'はい',
          handler: async () => {
            localStorage.clear();
            await this.storage.clear();
            await this.auth.signOut();
            this.navCtrl.navigateForward('/auth/signin');
          },
        },
      ],
    });
    alert.present();
  }

  public async doDataDeleted() {
    const alert = await this.alertCtrl.create({
      header: 'データ削除',
      message: '本デバイスに登録されているすべてのデータを削除します。',
      buttons: [
        {
          text: '閉じる',
          role: 'cancel',
        },
        {
          text: '削除',
          handler: () => {
            this.storage.clear().then(() => this.doSignout());
          },
        },
      ],
    });
    alert.present();
  }

  unsorted(a, b) {
    return 1;
  }
}
