<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-title>{{ title }}</ion-title>
    <ion-text slot="end" class="ion-padding-end">{{ emailUser }}</ion-text>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">{{ title }}</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-list>
    <ng-container>
      <ion-list-header><ion-label>トランザクション</ion-label></ion-list-header>
      @for (t of SlipType | keyvalue: unsorted; track t; let i = $index) {
        <ion-item button="true" (click)="doTransaction(t.key)">
          <ion-icon slot="start" name="server-outline" class="menu-icon color-primary"></ion-icon>
          <ion-label>{{ t.value }}</ion-label>
        </ion-item>
      }
      <ion-item button="true" (click)="doRestore()" lines="full">
        <ion-icon slot="start" name="clipboard-outline" class="menu-icon color-success"></ion-icon>
        <ion-label>復元</ion-label>
      </ion-item>

      <ion-list-header>
        <ion-label>タスク</ion-label>
      </ion-list-header>
      <ion-item button="true" lines="full" routerLink="task/choice">
        <ion-icon slot="start" name="checkbox-outline" class="menu-icon color-danger"></ion-icon>
        <ion-label>Dタスク</ion-label>
      </ion-item>

      <ion-list-header>
        <ion-label>伝票検索</ion-label>
      </ion-list-header>
      <ion-item button="true" lines="full" routerLink="search-slip">
        <ion-icon slot="start" name="search-outline" class="menu-icon color-success"></ion-icon>
        <ion-label>伝票検索</ion-label>
      </ion-item>
    </ng-container>

    @if (!platform.is('hybrid')) {
      <ion-list-header><ion-label>管理処理</ion-label></ion-list-header>
      <ion-item routerLink="/control/csv-update" lines="full">
        <ion-icon slot="start" name="cloud-upload-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>CSVインポート</ion-label>
      </ion-item>
      <ion-item routerLink="/control/close" lines="full">
        <ion-icon slot="start" name="lock-closed-outline" class="menu-icon color-primary"></ion-icon>
        <ion-label>請求締め処理</ion-label>
      </ion-item>
    }

    <ion-list-header>
      <ion-label>マスタ</ion-label>
    </ion-list-header>
    <ion-item routerLink="/resources/shop" lines="full">
      <ion-icon slot="start" name="home-outline" class="menu-icon color-primary"></ion-icon>
      <ion-label>店舗マスタ</ion-label>
    </ion-item>
    <ion-item routerLink="/resources/sku" lines="full">
      <ion-icon slot="start" name="file-tray-outline" class="menu-icon color-primary"></ion-icon>
      <ion-label>商品マスタ</ion-label>
    </ion-item>
    <ion-item routerLink="/resources/company" lines="full">
      <ion-icon slot="start" name="water-outline" class="menu-icon color-primary"></ion-icon>
      <ion-label>企業・企業管理マスタ</ion-label>
    </ion-item>
    <ion-item routerLink="/resources/subject" lines="full">
      <ion-icon slot="start" name="flag-outline" class="menu-icon color-primary"></ion-icon>
      <ion-label>件名マスタ</ion-label>
    </ion-item>
    <ion-item routerLink="/resources/ae" lines="full">
      <ion-icon slot="start" name="person-outline" class="menu-icon color-primary"></ion-icon>
      <ion-label>担当者マスタ</ion-label>
    </ion-item>
    <ion-item routerLink="/resources/price/company" lines="full">
      <ion-icon slot="start" name="pricetag-outline" class="menu-icon color-primary"></ion-icon>
      <ion-label>企業別単価マスタ</ion-label>
    </ion-item>
    <ion-item routerLink="/resources/price/subject" lines="full">
      <ion-icon slot="start" name="pricetag-outline" class="menu-icon color-primary"></ion-icon>
      <ion-label>件名単価マスタ</ion-label>
    </ion-item>
    <ng-container>
      <ion-list-header><ion-label>アカウント</ion-label></ion-list-header>
      <ion-item button="true" (click)="doSignout()" lines="full">
        <ion-icon slot="start" name="power-outline" class="menu-icon color-warning"></ion-icon>
        <ion-label>サインアウト</ion-label>
      </ion-item>
    </ng-container>

    <ion-list-header><ion-label>設定</ion-label></ion-list-header>
    <ion-item button="true" (click)="checkPosition()">
      <ion-label>現在地を表示</ion-label>
    </ion-item>
    <ion-item button="true" (click)="doDataDeleted()">
      <ion-label>デバイス内データを削除</ion-label>
    </ion-item>
    <ion-item lines="full">
      <ion-label>バージョン</ion-label>
      <ion-text slot="end">{{ version }}</ion-text>
    </ion-item>
  </ion-list>
</ion-content>
