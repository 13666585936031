import { inject } from '@angular/core';
import { Routes } from '@angular/router';
import { HomePage } from './home/home.page';
import { GuardService } from '../../../shared/src/auth/guard.service';

export const routes: Routes = [
  {
    path: '',
    component: HomePage,
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  {
    path: 'transaction',
    loadChildren: () => import('./transaction/transaction.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  { path: 'auth', loadChildren: () => import('../../../shared/src/auth/auth.routes').then((m) => m.routes) },
  {
    path: 'task',
    loadChildren: () => import('./task/task.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  {
    path: 'search-slip',
    loadChildren: () => import('./search-slip/search-slip.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  {
    path: 'resources',
    loadChildren: () => import('./resources/resources.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
  {
    path: 'control',
    loadChildren: () => import('./control/control.routes').then((m) => m.routes),
    canActivate: [() => inject(GuardService).requireAuthorized()],
  },
];
